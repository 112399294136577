import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"

const PublicProgramsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      reportImg: file(relativePath: { eq: "public-programs.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Public Programs" />
      <Hero
        pageTitle="Public Programs"
        breadcrumbText="Services"
        breadcrumbLink="/#services"
      />
      <section className="section-1 highlights image-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <span className="pre-title m-0">Communicate &amp; Educate</span>
              <h2>
                Public{" "}
                <span className="featured">
                  <span>Programs</span>
                </span>
              </h2>
              <p className="text-max-800">
                We have extensive experience constructing public programs that
                communicate and educate the general public as well as state and
                local decision makers of the benefits your airport provides. We
                utilize traditional face-to-face forums allowing questions to be
                answered in person.
              </p>
              <p>
                We generate charts, graphs, PowerPoint presentations, videos,
                brochures and websites that tell your story, the way you want
                your story told. We are creative and successful in presenting
                facts in a clear and understandable manner that wins the hearts
                and minds of your audience.
              </p>
              <Link to="/contact">Contact us about Public Programs</Link>
            </div>
            <div className="col-lg-4 mt-5">
              <div className="d-none d-lg-block">
                <Img fluid={data.reportImg.childImageSharp.fluid} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PublicProgramsPage
