import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import PropTypes from "prop-types"

const Hero = ({ pageTitle, breadcrumbText, breadcrumbLink }) => {
  const data = useStaticQuery(graphql`
    query {
      topImage: file(relativePath: { eq: "01.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section id="slider" className="hero p-0 odd featured mt-5 mt-lg-0">
      <div className="swiper-container no-slider animation slider-h-40 slider-h-auto">
        <div className="swiper-wrapper">
          <BackgroundImage
            Tag="section"
            className="swiper-slide slide-center post-hero"
            fluid={data.topImage.childImageSharp.fluid}
          >
            <div className="slide-content row text-center">
              <div className="col-12 mx-auto inner">
                <nav
                  data-aos="zoom-out-up"
                  data-aos-delay="800"
                  aria-label="breadcrumb"
                >
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    {breadcrumbText && (
                      <li className="breadcrumb-item">
                        <Link to={breadcrumbLink}>{breadcrumbText}</Link>
                      </li>
                    )}
                    <li className="breadcrumb-item active" aria-current="page">
                      {pageTitle}
                    </li>
                  </ol>
                </nav>
                <h1 className="mb-0 title effect-static-text">{pageTitle}</h1>
              </div>
            </div>
          </BackgroundImage>
        </div>
      </div>
    </section>
  )
}

Hero.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  breadCrumbText: PropTypes.string,
  breadCrumbLink: PropTypes.string,
}

export default Hero
